// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyArpNuiX6g3yhnNOEa5c863tjm15b8OhRo",
  authDomain: "legallawclone15.firebaseapp.com",
  projectId: "legallawclone15",
  storageBucket: "legallawclone15.appspot.com",
  messagingSenderId: "559041924142",
  appId: "1:559041924142:web:189b9dcd785c7ce3bce3bf"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;